.declaration_wrap {
  --height: 40px;
  --top: 3.5rem;
  --left: 2rem;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18%;
}
@media screen and (max-width: 1100px) {
  .declaration_wrap {
    padding: 0 12%;
  }
}

.declaration_float {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.declaration_container {
  width: 100%;
  min-width: min(80vw, 50rem);
  height: fit-content;
  max-width: 57.5rem;
  z-index: 9;
  overscroll-behavior: contain;
  flex-direction: row;
  overflow-y: auto;
  position: relative;
  border-radius: 0.25rem;
}

.declaration_popup_container {
  position: relative;
  padding: 1.5rem 4rem;
  background-color: #fff;
  margin: auto 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.dec_table_wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.dec_table_wrap > .form_management_wrapper {
  margin-bottom: 0;
}

.declaration_popup_container .fgRow {
  padding: 0;
}
.declaration_popup_container .fgHead {
  padding: 0.5rem;
  font-weight: 1rem;
}

.dec_popUpIcon_div {
  height: 1rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  /* z-index: 9; */
}

.dec_popUpIcon_div:hover path {
  opacity: 0.7;
  transition: all 500ms ease;
}

.dec_title_wrapper {
  padding: 2rem;
  padding-top: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
}
.dec_title_head {
  font-size: 1.25rem;
  font-weight: 600;
}
.dec_title_head.dec_body_head {
  text-transform: uppercase;
}
p.dec_subtitle_head,
p.dec_subtitle_txt {
  margin: 0;
}
p.dec_subtitle_head {
  font-size: 0.95rem;
  font-weight: 600;
}
p.dec_subtitle_txt {
  font-weight: 400;
}

.dec_client_details_tab_wrapper {
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.dec_waste_details_tab_wrapper {
  width: 100%;
  background-color: rgb(255, 255, 255);
}

/* Client Table Start */

.dec_client_details_tab_wrapper .cell,
.dec_waste_details_tab_wrapper .cell {
  font-size: 0.95rem;
  text-align: center;
  --cell_padding: 0.5rem;
  padding: var(--cell_padding);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dec_client_details_tab_wrapper .cell.value_cell,
.dec_client_details_tab_wrapper .cell div.value_cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dec_client_details_tab_wrapper > div {
  display: grid;
  grid-template-columns: 2fr 5fr;
  width: 100%;
  min-width: 0;
  background-color: rgb(255, 255, 255);
  --border: 1px solid var(--borderColor);
  border-bottom: var(--border);
}
.dec_client_details_tab_wrapper > div:last-child {
  border-bottom: none;
}

.dec_client_details_tab_wrapper > div > div.cell {
  min-width: 0;
  border-right: var(--border);
}

.dec_client_details_tab_wrapper > div > div.cell.dec_doc_row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 0;
}
.dec_client_details_tab_wrapper > div > div.cell.dec_doc_row > div {
  border-right: 1px solid var(--borderColor);
  padding: var(--cell_padding);
  align-self: stretch;
}
.dec_client_details_tab_wrapper > div > div.cell.dec_doc_row > div:last-child {
  border-right: none;
}

.dec_client_details_tab_wrapper > div > div.cell.dec_munic_row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
}

.dec_client_details_tab_wrapper > div > div.cell.dec_munic_row > div {
  border-right: 1px solid var(--borderColor);
  padding: var(--cell_padding);
  align-self: stretch;
}

.dec_client_details_tab_wrapper
  > div
  > div.cell.dec_munic_row
  > div:last-child {
  border-right: none;
}

.dec_client_details_tab_wrapper
  > div
  > div.cell.dec_munic_row
  > div.munic_cell {
  background-color: #f4f4f4;
}

.dec_client_details_tab_wrapper > div > div.cell:first-child {
  background-color: #f4f4f4;
  /* font-weight: 500; */
}
.dec_client_details_tab_wrapper > div > div.cell:last-child {
  border-right: none;
}
.dec_client_details_tab_wrapper > div > div.cell.span_full {
  background-color: #fff;
  grid-column: 1/3;
  text-align: left;
}

/* Client Table End */

/* Waste table start */

.dec_waste_details_tab_wrapper > div {
  display: grid;
  grid-template-columns: 1.5fr 3fr 1fr 1fr 1fr;
  width: 100%;
  min-width: 0;
  background-color: rgb(255, 255, 255);
  --border: 1px solid var(--borderColor);
  border-bottom: var(--border);
}
.dec_waste_details_tab_wrapper > div:first-child {
  background-color: #f4f4f4;
  font-weight: 600;
}
.dec_waste_details_tab_wrapper > div:last-child {
  border-bottom: none;
}

.dec_waste_details_tab_wrapper > div > div.cell {
  min-width: 0;
  border-right: var(--border);
}
.dec_waste_details_tab_wrapper > div > div.cell:last-child {
  border-right: none;
}

/* Waste table end */

.dec_bottom_text,
.dec_sign_wrapper {
  display: flex;
  justify-content: flex-end;
}
.dec_bottom_text {
  margin: 0.75rem 0;
  font-weight: 600;
  font-size: 0.85rem;
}

.dec_sign_container {
  width: fit-content;
}
.dec_sign_box {
  max-width: 100%;
  width: 12.5rem;
  height: 4.5rem;
  border-bottom: 1.5px solid var(--borderColor);
}
.dec_sign_holder {
  font-size: 0.85rem;
  margin: 0.5rem 0;
  text-align: center;
}

.dec_footer_wrapper {
  padding: 1rem 0;
  font-size: 0.8rem;
  padding-top: 3rem;
}

.dec_button_wrapper {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  justify-content: center;
}

.dec_button_wrapper > button {
  padding: 0.375rem 2rem;
  min-width: 12rem;
  overflow-wrap: anywhere;
}

svg {
  cursor: pointer;
  transition: all 200ms ease;
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

@media (max-width: 1024px) {
  .declaration_popup_container {
    padding: 1rem 2rem;
  }
}

@media (max-width: 640px) {
  .declaration_wrap {
    padding: 2rem 10%;
  }
}

/*Scroller*/
.declaration_scroller::-webkit-scrollbar {
  width: 0.75rem;
}
.declaration_scroller::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  overflow: hidden;
  padding: 0 0.25rem;
}
.declaration_scroller::-webkit-scrollbar-thumb {
  background-color: #aaa;
}
.declaration_scroller::-webkit-scrollbar-thumb:hover {
  background-color: rgb(159, 159, 159);
}
