.user-activity {
  margin-top: 40px;
  .restore_svg_user_Activities {
    width: 2rem;
  }
  .DATASET__wrapper {
    .DATASET__form-custom {
      display: grid;
      grid-template-columns: 18% 16% 16% 16% 17% 17%;
    }
  }
}
