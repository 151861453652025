.limitia_box_tab.waste_accpt_tab {
  grid-template-columns: 1fr 2fr 2fr 1fr;
}
.limitia_box_progress_handle2 {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 9;
  position: absolute;
  top: 0;
  height: 100%;
  width: 4rem;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  opacity: 0.56;
}
.blackLimit {
  display: inline-flex;
  background-color: #ededed;
  height: 1rem;
  width: 2rem;
  margin: 0 4px;
}
.blackLimit.midAlign {
  align-self: center;
}
