body {
  // overflow-x: hidden;
  background-color: #f7f7f7 !important;
}

.HEADER__menu {
  li {
    a {
      text-transform: unset;
    }
  }
}

.submenu {
  align-items: center !important;
  padding: 40px;
  display: flex;
  justify-content: flex-start;

  .nav-link {
    display: flex !important;
    justify-content: center;
  }
}

.DATASET__form {
  margin: 0 40px 0 40px;
  box-shadow: none;
}

.DATASET__data {
  display: grid;
}

.DATASET__form-static {
  margin: 0 !important;
  padding: 15px;
}

.DATASET__form-title {
  font-weight: 700 !important;
  padding: 15px !important;
  text-align: left;
  align-items: unset;

  + .DATASET__form-input {
    margin: 10px 5px 10px 10px !important;
  }
}

.DATASET__form-input {
  margin: 10px 5px 10px 5px !important;

  &:last-child {
    margin: 10px 10px 10px 5px !important;
  }

  &.REMOVE {
    -ms-grid-column: 3;
    grid-column: 3;

    button {
      color: #2a2a2a;
      font-size: 14px;
      background: transparent;
      border: none;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;

      img {
        display: block;
        margin-right: 5px;
        height: 12px;
      }
    }
  }
}

.DATASET__form.GRID4_ext {
  grid-template-columns: 20% 25% calc(30% - 7rem) 7rem 25%;
}

.DATASET__form.GRID5_ext {
  grid-template-columns: 10% 20% 20% calc(30% - 7rem) 7rem 20%;
}

.DATASET__form.GRID6_ext {
  grid-template-columns: 10% 20% 20% 20% 10% 10% 15%;
}

.DATASET__form.GRID7_ext {
  grid-template-columns: 10% 20% 10% 7rem 15% 12% calc(20% - 7rem) 13%;
}

.DATASET__form.GRID8_ext {
  grid-template-columns: 10% 18% 7% calc(28% - 7rem) 7rem 10% 7% 8% 12%;
}

.DATASET__disabled {
  z-index: 99;
}

.table-action {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 0 0 10px 0;
  gap: 0.5rem 0.75rem;
  flex-wrap: wrap;
}

.upload_wrapper {
  height: 35px;
}

.btn {
  display: inline-block;
  font-weight: bold;
  border: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  white-space: normal;
  min-height: 45px;
  width: fit-content;
  height: fit-content;
  border-radius: 0;
  min-width: 80px;
  border-radius: 3px;

  &:hover {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
}

.btn-stretch {
  width: 100%;
}

.btn-edit {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(/assets/images/edit-green.png) no-repeat;
  background-size: 21px 21px;
  box-shadow: unset;
  border: none;

  &:hover {
    box-shadow: unset;
  }
}

.btn-del {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(/assets/images/del.png) no-repeat;
  background-size: 16px 20px;
  box-shadow: unset;
  border: none;

  &:hover {
    box-shadow: unset;
  }
}

.btn-sort {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 57px;
  width: 180px;
  background-color: #ffffff;
  color: #000000;
  max-width: unset;
  border: 0;
  box-shadow: 1px 1px 10px #999999;
  margin: 0 0 15px 15px;

  &:hover {
    box-shadow: 0px 0px 5px #999999;
  }

  .bg-sort {
    display: block;
    width: 18%;
    height: 100%;
    background: url(/assets/images/down-arrow.svg) no-repeat;

    &.asc {
      background-position-x: 15px;
      background-position-y: 20px;
      transform: rotate(180deg);
    }

    &.desc {
      background-position-x: 0;
      background-position-y: 20px;
    }
  }

  &:hover {
    color: #383636;
  }
}

.btn_filter_wrapper {
  display: inline-flex;
  align-items: center;

  .btn-filter {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 32px;
    background-color: #43a047;
    color: #ffffff;
    max-width: unset;
    border: 0;
    font-size: 14px;
    padding: 2px 1rem;
    box-shadow: 1px 1px 10px #999999;

    &:hover {
      box-shadow: 0px 0px 5px #999999;
    }

    .txt {
      flex: 1;
      display: block;
      width: auto;
      margin-right: 1rem;
    }
    path {
      fill: #fff;
    }
  }
}

.btn-clear-filter {
  .bg {
    display: block;
    width: 30px;
    background-size: 16px 16px;
    padding: 0 0.125rem;
    // background: url(/assets/images/times-c.png) no-repeat 0% 50%;
  }
}

.btn-add {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  --height: 35px;
  height: var(--height);
  background-color: #43a047;
  color: #ffffff;
  border: 0;
  padding: 0 20px 0 20px;
  box-shadow: 1px 1px 10px #999999;

  &:hover {
    box-shadow: 0px 0px 5px #999999;
  }
}

.btn-save {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
  background-color: #43a047;
  color: #ffffff;
  margin: 0 0 15px 15px;
  border: 0;
  padding: 0 20px 0 20px;
  box-shadow: 1px 1px 10px #999999;

  &:hover {
    box-shadow: 0px 0px 5px #999999;
  }
}

.btn-green {
  background-color: #43a047;
  color: #ffffff;
  transition: background-color 200ms ease;

  &:hover {
    background-color: #39873d;
    color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
}

.btn-red {
  background-color: #fd4b1a;
  color: #ffffff;
  transition: background-color 200ms ease;

  &:hover {
    background-color: #e3410b;
    color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
}

.btn-orange {
  background-color: #fb8c20;
  color: #ffffff;
  transition: background-color 200ms ease;

  &:hover {
    background-color: #e07b00;
    color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
}

.btn-disabled {
  background-color: #f2f2f2;
  color: #9f9f9f;

  &:hover {
    color: #9f9f9f;
  }
}

.btn-default {
  background-color: #ffffff;
  color: #000000;
  transition: background-color 200ms ease;

  &:hover {
    color: #000000;
    background-color: #f2f2f2;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
}

.btn-expand {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  color: #000000;
  max-width: unset;
  border: 0;
  margin: 0 0 0 15px;
  text-decoration: underline;

  &:hover {
  }

  .bg-expand {
    display: block;
    width: 18%;
    height: 16px;
    background: url(/assets/images/down-arrow.svg) no-repeat;

    &.asc {
      background-position-x: 15px;
      background-position-y: 3px;
      transform: rotate(180deg);
    }

    &.desc {
      background-position-x: 0;
      background-position-y: 3px;
    }
  }

  &:hover {
    color: #383636;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: row;
}

.flex-row-all-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.txt-error {
  color: #fd4b0d;
}

.bg-error {
  border: 1px solid #fd4b0d;
  background-color: #ffdbce;
}

.both-error {
  color: #fd4b0d;
  border: 1px solid #fd4b0d;
  background-color: #ffdbce;
}

.text-strong {
  font-weight: bolder;
}

#overlay {
  cursor: default !important;
}

#overlay-x {
  top: 15px;
  right: 15px;
}

.border {
  border: 1px solid #9f9f9f !important;
}
.brd-btm {
  border-bottom: 1px solid #9f9f9f !important;
}
.no-border {
  border: none !important;
}

input {
  display: block;
  border: 1px solid #9f9f9f !important;
  border-radius: 2px;
  height: 45px;
  width: 100%;
  text-align: left;
  font-family: "Mukta";
  padding: 5px 10px;
  outline: none;
}

textarea {
  display: block;
  border: 1px solid #9f9f9f !important;
  border-radius: 2px;
  height: 45px;
  width: 100%;
  text-align: left;
  font-family: "Mukta";
  padding: 5px 10px;
  outline: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .bare-btn {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 45px;
    background: transparent;
    color: #000000;
    border: 0;
    padding: 0 10px 0 10px;

    .txt {
      display: block;
      width: auto;
      text-decoration: underline;
    }
  }

  .btn-plus {
    display: block;
    padding-left: 40px;
    background-size: 32px 32px;
    background-image: url(/assets/images/plus.png);
    background-repeat: no-repeat;
    background-position: 0px 5px;
  }
}

@include media-breakpoint-between(lg, xxl) {
  .bare-btn {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    background: transparent;
    color: #000000;
    border: 0;
    padding: 0 10px 0 10px;

    .txt {
      display: block;
      width: auto;
      text-decoration: underline;
    }
  }

  .btn-plus {
    display: block;
    padding-left: 30px;
    background-size: 22px 22px;
    background-image: url(/assets/images/plus.png);
    background-repeat: no-repeat;
    background-position: 0px 5px;
  }
}

@include media-breakpoint-down(lg) {
  .bare-btn {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 25px;
    background: transparent;
    color: #000000;
    border: 0;
    padding: 0 10px 0 10px;

    .txt {
      display: block;
      width: auto;
      text-decoration: underline;
    }
  }

  .btn-plus {
    display: block;
    padding-left: 20px;
    background-size: 12px 12px;
    background-image: url(/assets/images/plus.png);
    background-repeat: no-repeat;
    background-position: 0px 5px;
  }
}
