.iconContainer {
  width: 1.25rem;
  padding: 0 0.25rem;
  cursor: pointer;
}

.iconContainer.lg {
  width: 1.625rem;
  padding: 0 0.3125rem;
  cursor: pointer;
}

.iconContainer.defaultCursor {
  cursor: default;
}

.iconContainer:hover {
  opacity: 0.85rem;
}

.iconContainer img,
.iconContainer svg {
  object-fit: contain;
  height: 100%;
  /* width: 100%; */
  border-radius: inherit;
}
.iconContainer.hover:hover img,
.iconContainer.hover:hover svg {
  opacity: 0.8;
}
