@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

.wMgtBtn {
  display: flex;
  justify-content: center;
}
.bold_tail_wMgt {
  font-weight: bold;
}
.bold_tail_wMgt.amt {
  text-align: center;
}

@include media-breakpoint-up(xxl) {
  .waste-management {
    margin-top: 10px;

    .HOMEPAGE__section-custom {
      align-items: center !important;
      padding: 40px;
      display: flex;
      justify-content: flex-start;

      .nav-link {
        display: flex !important;
        justify-content: center;
      }
    }

    .DATASET__header-custom {
      position: relative;
    }

    .DATASET__wrapper {
      background-color: #ffffff !important;

      .DATASET__form-custom {
        position: relative;
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;
        border: 1px solid #999999;
      }

      .DATASET__data {
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;
      }

      .DATASET__summary {
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;

        .DATASET__form-static {
          font-weight: bold;
        }
      }
    }
  }

  .DATASET__form-static {
    border-bottom: 1px solid #9f9f9f;

    &.left-border {
      border-left: 1px solid #9f9f9f;
    }

    &.right-border {
      border-right: 1px solid #9f9f9f;
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .waste-management {
    margin-top: 10px;

    .HOMEPAGE__section-custom {
      align-items: center !important;
      padding: 40px;
      display: flex;
      justify-content: flex-start;

      .nav-link {
        display: flex !important;
        justify-content: center;
      }
    }

    .DATASET__header-custom {
      position: relative;
    }

    .DATASET__wrapper {
      background-color: #ffffff !important;

      .DATASET__form-custom {
        position: relative;
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;
        border: 1px solid #999999;
      }

      .DATASET__data {
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;
      }

      .DATASET__summary {
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;

        .DATASET__form-static {
          font-weight: bold;
        }
      }
    }
  }

  .DATASET__form-static {
    border-bottom: 1px solid #9f9f9f;

    &.left-border {
      border-left: 1px solid #9f9f9f;
    }

    &.right-border {
      border-right: 1px solid #9f9f9f;
    }
  }
}

@include media-breakpoint-down(lg) {
  .waste-management {
    margin-top: 10px;

    .HOMEPAGE__section-custom {
      align-items: center !important;
      padding: 40px;
      display: flex;
      justify-content: flex-start;

      .nav-link {
        display: flex !important;
        justify-content: center;
      }
    }

    .DATASET__header-custom {
      position: relative;
    }

    .DATASET__wrapper {
      background-color: #ffffff !important;

      .DATASET__form-custom {
        position: relative;
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;
        border: 1px solid #999999;
      }

      .DATASET__data {
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;
      }

      .DATASET__summary {
        display: grid;
        grid-template-columns: 20% 20% 13% 13% 20% auto;

        .DATASET__form-static {
          font-weight: bold;
        }
      }
    }
  }

  .DATASET__form-static {
    border-bottom: 1px solid #9f9f9f;

    &.left-border {
      border-left: 1px solid #9f9f9f;
    }

    &.right-border {
      border-right: 1px solid #9f9f9f;
    }
  }
}
