.report {
  /* --height: 45px; */
  padding: 0 var(--genSidePadding);
  margin-top: 1rem;
  margin-bottom: 30rem;
}
.report_search_wrapper {
  padding: 4rem;
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
  max-width: 40rem;
}
.report_search_wrapper > p,
.report_search_wrapper > div {
  width: 100%;
}
.report_search_wrapper p {
  text-align: center;
  margin: 0;
  font-weight: 700;
  margin-bottom: 2rem;
}
.report_option_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 0 0 10px 0;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.report_option {
  width: 15.5rem;
  border: 1px solid var(--borderColor);
  background-color: #fff;
  height: 2.4rem;
  border-radius: var(--rad);
}
.report_wrapper {
  margin-top: 1.25rem;
}
.report_btn_container {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
}
.report_btn {
  display: block;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  border: none;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  white-space: normal;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  min-height: 45px;
  border-radius: 0;
  min-width: 80px;
  border-radius: var(--rad);
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.report_btn.loading_report_btn {
  opacity: 0.65;
  pointer-events: none;
}
.report_btn:disabled {
  opacity: 0.65;
  pointer-events: none;
}

.report_label {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
}
.reportBox_wrapper {
  margin-bottom: 4.5rem;
  --input_height: 40px;
}
.report_box_tab {
  gap: 0.75rem;
  display: grid;
  align-items: center;
}
.report_box {
  display: flex;
  align-items: flex-start;
}
.report_box > div {
  flex: 1;
}
.report_filter_wrapper {
  height: auto;
}
.report_btn_filter_wrapper {
  margin: 0 0 0.5rem 0.5rem;
  padding: 0.25rem;
  display: inline-flex;
  align-items: center;
}

.report_btn_filter {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  align-items: center;
  min-height: 32px;
  background-color: #43a047;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  padding: 0.25rem 0.5rem;
  padding-left: 1rem;
  box-shadow: 2px 4px 10px #0000001e;
}

.report_btn_filter:hover {
  box-shadow: 0px 0px 5px #999999;
}

.report_btn_filter span {
  flex: 1;
  display: block;
  width: auto;
  margin-right: 1rem;
}
.report_btn_filter path {
  fill: #fff;
}
.report_notification_message_wrapper {
  position: fixed;
  z-index: 999;
  bottom: 3rem;
  left: 3rem;
  width: 30rem;
  max-width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem 0;
  max-height: 50rem;
  overflow-y: auto;
  padding: 1rem;
}
.report_notification_message_container {
  transition: all 3000ms ease;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-height: 3rem;
  width: 100%;
  background-color: #fff;
  font-weight: 600;
  border: 1px solid red;
  border-radius: var(--rad);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.report > button {
  background-color: var(--vaatcGreen);
  padding: 0.25rem;
  color: #fff;
  margin-right: 1rem;
  min-width: 4rem;
}
