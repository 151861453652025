.grid-cols {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.only-btn {
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  padding: 0 20px 0 0;
  width: 100%;

  display: flex;

  button {
    width: 100%;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }

    &:only-child {
      margin-right: 0;
      margin-left: 0;
    }
  }
}