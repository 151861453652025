.dropD {
  position: relative;
  flex: 1;
}
.fixedBox {
  position: fixed;
  height: 10rem;
  width: 3rem;
  background-color: #43a047;
}

.dropD_wrapper {
  display: flex;
  border: 1px solid #9f9f9f;
  border-radius: var(--rad);
  overflow: hidden;
}
.dropD_wrapper.reverse {
  flex-direction: row-reverse;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
.dropD_wrapper.dropFlip {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.dropD_wrapper.drop {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropD_wrapper input {
  border: none !important;
  border-radius: 0 !important;
  height: auto; /*this is fallback*/
  height: var(--height);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.dropD_wrapper input::placeholder {
  font-size: 0.9rem;
  color: rgb(159, 159, 159);
}
.dropD_wrapper input:focus {
  border: none;
}

.dropD_btn {
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  border-left: 1px solid #9f9f9f;
  transition: all 200ms ease;
}
.dropD_btn.reverse {
  border: 1px solid #9f9f9f;
  border-radius: inherit;
}
.dropD_btn:hover {
  background-color: #e5e5e5;
}

.dropD_btn > div {
  width: 1rem;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
}

/* Uncomment to rotate the drop button on click */
/* .dropD_wrapper.drop .dropD_btn > div {
    transform: rotateZ(-180deg);
  } */

.dropD_container {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #979797;
  height: max-content;
  font-size: 1rem;
  z-index: 99;
  max-height: 18rem;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 6px rgba(71, 71, 71, 0.125);
  border-bottom-left-radius: var(--rad);
  border-bottom-right-radius: var(--rad);
  border-top: none;
  transition: all 300ms ease;
  overscroll-behavior: contain;
}
.dropD_container.flip {
  border: 1px solid #979797;
  border-radius: 0;
  border-top-left-radius: var(--rad);
  border-top-right-radius: var(--rad);
  border-bottom: none;
}

.dropD_content {
  cursor: pointer;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  overflow-wrap: anywhere;
  border-bottom: 1px solid #dadada;
}
@media (max-width: 1024px) {
  .dropD_content {
    padding: 0.75rem;
  }
}

.dropD_content:hover {
  color: #fff;
  background-color: #43a047;
}
.dropD_content.picked,
.dropD_content.picked:hover {
  color: #000;
  background-color: #c8e6c9;
}
.dropD_content:last-child {
  border-bottom: none;
}
/* Scroller CSS for Drop Down */
.dropD_container::-webkit-scrollbar {
  width: 0.75rem;
}
.dropD_container::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* border-radius: 1rem; */
  overflow: hidden;
  padding: 0 0.25rem;
}
.dropD_container::-webkit-scrollbar-thumb {
  background-color: #aaa;
}
.dropD_container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(159, 159, 159);
}
.dropD_btn svg path {
  stroke: #2a2a2a;
  stroke-width: 0.6px;
}
