.contract_popup {
  padding-bottom: 10rem;
}
.contract_form_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 1rem 0;
}
.contract_form_wrapper > div {
  min-width: 0;
}
.contract_form_wrapper > div p {
  margin: 0;
  margin-bottom: 0.25rem;
  font-weight: 600;
}
