.newPopUp_wrap {
  --height: 40px;
  --top: 3.5rem;
  --left: 2rem;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 18%;
}
.newPopUp_wrap.fitContent {
  flex-direction: row;
}
.newPopUp_wrap.noTopPadding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.newPopUp_wrap.noVertPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.newPopUp_float {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.newPopUp_container {
  width: 100%;
  min-width: 64vw;
  z-index: 9;
  overscroll-behavior: contain;
  padding: var(--top) 0;
  padding-bottom: 2rem;
  overflow-y: auto;
  position: relative;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.newPopUp_container.fitContent {
  max-width: min(100%, 35rem);
  max-height: 100%;
  height: fit-content;
  min-width: 35rem;
  padding: 2rem;
  padding-top: 4rem;
  position: relative;
  background-color: #fff;
  overscroll-behavior: contain;
  overflow-y: auto;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.newPopUp_header {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  position: absolute;
  height: 3.5rem;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  font-weight: 700;
}
.newPopUp_header.makeShadow {
  z-index: 9;
  box-shadow: 0 1.95px 2.6px rgba(0, 0, 0, 0.15);
}
.popUpIcon_div {
  height: 1rem;
}

.popUpIcon_div:hover path {
  opacity: 0.7;
  transition: all 500ms ease;
}
svg {
  cursor: pointer;
  transition: all 200ms ease;
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

@media (max-width: 640px) {
  .newPopUp_wrap {
    padding: 2rem 10%;
  }
}
