.profile {
  border-radius: 0.125rem;
  background-color: #fff;
  border: 1px solid var(--borderColor);
  margin-bottom: 2rem;
  width: 100%;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  --mTop: 6rem;
  --vertMargin: 2.5rem;
  --sideMargin: 4rem;
  --space: 12%;
  padding: var(--vertMargin) var(--sideMargin);
}
.profile_banner {
  background: #c8e6c9;
  padding: 2rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #43a047;
  font-weight: 600;
  font-size: 1rem;
}

.profile_wrapper {
  --height: 2.875rem;
}

.profile_header_wrapper {
  font-weight: 700;
  padding-bottom: 0;
}
.profile_header_wrapper h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
}
.profile_header_wrapper h5 {
  font-weight: 600;
  font-size: 1.125rem;
  color: rgb(136, 140, 136);
}
.profile_container {
  display: flex;
  width: 100%;
}
.profile_debt_wrapper {
  flex: 1;
  /* width: calc(100% / 3); */
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  margin-left: calc(var(--space) / 2);
}
.profile_form {
  flex: 2;
}
.profile_debt_wrapper,
.profile_form_wrapper {
  padding: 2rem 0;
  padding-bottom: 0;
}
.profile_form_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}
.profile_form_record {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 calc(var(--space) * 2 / 3);
}
.profile_form_record .split {
  display: grid;
  gap: 0 1rem;
  grid-template-columns: repeat(2, 1fr);
}
.profile_form_header,
.profile_form_container {
  max-width: 25rem;
  min-width: 0;
}
.profile_form_container {
  min-height: 2.875rem;
  display: flex;
  --height: 2.875rem;
  --Height: var(--height);
}
.profile_form_container.split {
  display: grid;
  gap: 1rem;
}
.profile_form_container > div {
  flex: 1;
}
.profile_form_container_input_div {
  height: 2.875rem;
}

.profile_form_container_input_div .actions {
  display: flex;
  justify-content: flex-end;
}
.profile_form_container_input_div > input {
  height: var(--Height);
}
.profile_form_container_input_div > .dropD {
  --height: calc(var(--Height) - 2px);
}
.profile_form_header {
  overflow-wrap: anywhere;
  align-items: flex-end;
}
.profile_form_header,
.profile_debt_title,
.profile_debt_label {
  font-weight: 600;
  font-size: 1.125rem;
  padding-bottom: 0.875rem;
  display: flex;
}
.profile_debt_amount h3 {
  padding-bottom: 1rem;
  font-size: 2.8125rem;
  font-weight: 700;
  color: #3ea754;
}
.profle_deficit_input_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding-bottom: 1rem;
  margin-bottom: 0.75rem;
  gap: 1rem;
}
.profle_deficit_input_wrapper button {
  height: auto;
}

.profile_debt_btn_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 14px 2rem;
}

.profile_debt_title {
  padding: 0;
}
.profile_debt_btn_wrapper button {
  display: inline-flex !important;
  min-width: 10rem;
  min-height: 45px;
  overflow-wrap: anywhere;
  height: auto;
  padding: 0.25rem 1rem;
}
.profile_debt_wrapper_input_div {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
.profile_debt_wrapper_input_div div,
.profile_debt_wrapper_input_div input {
  width: 100%;
}
.profile_btn_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_btn_wrapper button:nth-child(1) {
  margin-right: 2rem;
}
.profile_btn {
  padding: 0.375rem 2rem;
  margin-top: 3.75rem;
  min-width: 12rem;
  overflow-wrap: anywhere;
}
