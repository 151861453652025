.suggestion_wrapper {
  --size: 3.2rem;
  --shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  width: var(--size);
  height: var(--size);
  position: fixed;
  bottom: 1.5rem;
  z-index: 999;
  right: 1.5rem;
  box-shadow: var(--shadow);
  background-color: #fff;
  border-radius: 50%;
}
.suggestion_wrapper .sign {
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 10rem;
  height: 10rem;
  background-color: #fff;
}

.suggestion_wrapper:hover {
  background-color: rgb(249, 249, 249);
}
.suggestion_wrapper .suggestion_icon_wrapper {
  cursor: pointer;
  padding: 0.75rem;
}
.float_notif {
  height: 1rem;
  width: 1rem;
  background-color: #dc3545;
  position: absolute;
  top: -1px;
  right: -3px;
  border-radius: 50%;
}
.suggestion_header_wrapper {
  display: flex;
  align-items: center;
}
.suggestion_header_item {
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  transition: all 200ms ease;
  border-bottom: 2px solid transparent;
}
.suggestion_header_item.active {
  border-bottom: 2px solid var(--vaatcGreen);
  color: var(--vaatcGreen);
}
.suggestion_header_item span.red_dot {
  height: 6px;
  width: 6px;
  background-color: #dc3545;
  border-radius: 50%;
  margin-left: 0.5rem;
}
.suggestion_body {
  padding: 0 2rem;
  height: 100%;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;
  display: flex;
}
.suggestion_header_item:hover {
  background-color: #f2f2f2;
}

/* Suggestion Form */
.suggestion_form {
  display: flex;
  height: 100%;
  flex: 1;
}
.suggestion_form_cont {
  margin-bottom: 1rem;
}
.suggestion_form_cont textarea {
  resize: vertical;
  min-height: 10rem;
}
.suggestion_form_left {
  flex: 1;
  padding-right: 2rem;
  padding-top: 1rem;
}
.suggestion_form_right {
  width: 40%;
  max-height: 100%;
  max-width: 15rem;
  background-color: #eee;
  border-radius: 0.25rem;
  padding: 0.5rem;
  overflow: auto;
}
.suggestion_img_label_wrap {
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  padding-top: 0;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: var(--shadow);
  border-radius: inherit;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 200ms ease;
}
.suggestion_img_label_wrap.fileOver {
  outline: 2px solid black;
}
.suggestion_img_drop {
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  width: 100%;
}
.suggestion_img_drop p {
  margin: 0;
}

.suggestion_img_label_wrap:hover {
  background-color: rgb(249, 249, 249);
}
.suggestion_img_label_wrap label {
  box-shadow: var(--shadow);
  background-color: #f2f2f2;
  padding: 0.25rem 0.5rem;
  /* color: var(--vaatcGreen); */
  font-size: 0.8rem;
  margin: 0;
  flex: 1;
  cursor: pointer;
}

.suggestion_img_label_wrap input {
  height: 0;
  display: none;
}
.sug_img_form_wrap {
  border-top: 1px solid #ccc;
  padding-top: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}
.sug_img_form {
  margin-bottom: 0.5rem;
  /* box-shadow: var(--shadow); */
  height: 7rem;
  background-color: #fff;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.sug_img_form .float_icon {
  position: absolute;
  height: 0.75rem;
  top: 0.5rem;
  right: 0.5rem;
}
.sug_img_form .float_icon:hover {
  opacity: 0.65;
}

/* Suggestion List */
.suggestionList {
  padding-top: 4px;
  font-size: 0.9rem;
}
.suggestionList_wrapper {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: var(--rad);
  box-shadow: var(--shadow);
  border: 1px solid #ccc;
}
.suggestionList_item {
  background-color: rgb(245, 245, 245);
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  font-weight: 300;
}
.suggestionList_item_date {
  cursor: pointer;
  padding: 0.5rem;
  padding-bottom: 0;
  font-size: 0.75rem;
  z-index: 2;
  position: relative;
}
.suggestionList_item_col {
  cursor: pointer;
  padding: 0.5rem;
  padding-top: 0;
  display: grid;
  grid-template-columns: 8rem 1.4fr 1.4fr 4rem 3rem;
}
.suggestionList_item.active .suggestionList_item_col {
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
}
.suggestionList_item_col span {
  padding: 0 3px;
}
.suggestionList_item_col span:first-child {
  padding-left: 0;
}
.suggestionList_item.unread {
  font-weight: 700;
  background-color: #fff;
  color: #212529;
}
.suggestionList_item.read .suggestionList_item_col,
.suggestionList_item.read .suggestionList_item_date {
  color: #343a40;
}
.suggestionList_item:hover {
  border-bottom: 1px solid #ddd;
}
.suggestionList_item:last-child,
.suggestionList_item:last-child:hover {
  border-bottom: none;
}
.suggestionList_item_col span.msg_list_icon_wrap {
  width: 1rem;
  margin-right: 1rem;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
  display: none;
}
.suggestionList_item:hover .msg_list_icon_wrap {
  display: flex;
}
.suggestionList_item.active .msg_list_icon_wrap {
  transform: rotateZ(180deg);
  display: flex;
}
.msg_link,
.msg_link a {
  font-weight: 400;
  color: var(--vaatcGreen) !important;
  font-size: 0.8rem;
}
.img_num {
  text-align: center;
  margin: auto 0;
  font-weight: 400;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  border: 1px solid #eee;
}
.suggestionList_item_msg {
  background-color: #eee;
  display: flex;
  /* min-height: 12rem; */
  padding: 1rem;
}
.suggestionList_item_img_lg {
  cursor: zoom-in;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
}
.suggestionList_item_img_cont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.25rem;
}
.suggestionList_item_img_sm {
  border: 2px solid transparent;
}
.suggestionList_item_img_sm.active {
  border: 2px solid var(--vaatcGreen);
}

.suggestionList_item_img_sm:hover {
  border: 2px solid #43a048bd;
}

.suggestionList_item_img_sm .inner .img,
.suggestionList_item_img_sm .inner img {
  background-color: rgb(211, 211, 211);
  cursor: pointer;
  flex: 1;
}
.suggestionList_item_msg .left_pane {
  width: min(50%, 16rem);
  padding-right: 1rem;
}
.suggestionList_item_msg .right_pane {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.suggestionList_item_msg .right_pane > div {
  margin-bottom: 0.5rem;
}
.suggestionList_item_msg .right_pane .title {
  padding-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.suggestionList_item_msg .right_pane .body {
  flex: 1;
}
.suggestionList_item_msg .right_pane .body p {
  margin-bottom: 0.25rem;
}

.suggestionList_item_msg .right_pane .officer {
  margin-top: 1rem;
  margin-bottom: 0;
  color: var(--vaatcGreen);
}

.exp_img_icon_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 50%;
  position: absolute;
  height: 1.5rem;
  background-color: rgba(137, 137, 137, 0.5);
  top: 1rem;
  right: 1rem;
}
.exp_img_wrap {
  --height: 40px;
  --top: 3.5rem;
  --left: 2rem;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 18%;
}
.exp_img_container {
  width: 100%;
  min-width: 64vw;
  z-index: 9;
  overscroll-behavior: contain;
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
@media (max-width: 640px) {
  .exp_img_wrap {
    padding: 2rem 10%;
  }
}
.chc {
  background-color: red;
  flex: 1;
}

.sug_balloon_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  display: flex;
  justify-content: flex-end;
}
.sub_balloon_box {
  position: absolute;
  bottom: 1rem;
  max-width: 20rem;
  pointer-events: none;
  width: max-content;
  --color: #c8e6c9;
  background-color: var(--color);
  padding: 0.65rem;
  font-size: 0.9rem;
  border-radius: var(--rad);
  opacity: 1 !important;
  z-index: 99;
  justify-self: center;
  display: flex;
  --borderSize: 1px;
  transform-style: preserve-3d;
  --borderColor: #ddd;
  border: var(--borderSize) solid var(--borderColor);
  --box-shadow: rgba(143, 143, 143, 0.12) 0px 2px 3px 0px;
  box-shadow: var(--box-shadow);
}
.sub_balloon_box:before {
  content: "";
  position: absolute;
  top: 100%;
  right: calc(var(--size) - 0.2rem);
  right: 1.1rem;
  border-bottom-right-radius: var(--rad);
  --size: 1rem;
  width: var(--size);
  z-index: -1;
  height: var(--size);
  background-color: var(--color);
  border-right: var(--borderSize) solid var(--borderColor);
  border-bottom: var(--borderSize) solid var(--borderColor);
  transform: translateZ(1px)
    translateY(calc(-1 * var(--size) / 2 + var(--borderSize))) rotateZ(45deg);
}
