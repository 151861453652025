.classifier_wrapper {
  margin-bottom: 8.5rem;
}
.classifier_wrapper p {
  margin-bottom: 1.25rem;
  font-weight: 600;
  font-size: 1.125rem;
}
.classifier_label {
  margin-top: 1.5rem;
}
.classifier_table {
  display: grid;
  grid-template-columns: 1fr minmax(10%, min(10%, 8rem)) 1fr;
  gap: 1rem 0;
}
.classifier_table_create {
  --height: 45px;
  align-items: center;
  display: flex;
}
.classifier_table_create > div:first-child {
  margin-right: 2rem;
  flex: 1;
}
.classifier_column {
  border: 1px solid var(--borderColor);
  grid-template-columns: 1fr;
  padding: 1rem;
  overflow-y: auto;
  background-color: #fff;
  min-height: 15rem;
  max-height: 25rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.classifier_item {
  border: 1px solid var(--borderColor);
  padding: 0.5rem;
  padding-right: 1rem;
  cursor: default;
  display: flex;
  margin-bottom: 1rem;
  border-radius: var(--rad);
}
.classifier_item span {
  cursor: default;
  flex: 1;
  padding-right: 0.5rem;
}
.classifier_item .iconContainer {
  align-items: center;
  justify-content: center;
  display: flex;
}

.classifier_item .iconContainer > div {
  margin: 0;
  cursor: default;
}
.classifier_item:hover {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  transition: 200ms ease;
}
.classifier_item.activated {
  border: 2px solid var(--vaatcGreen);
  padding: calc(0.5rem - 1px);
  padding-right: calc(1rem - 1px);
}
.classifier_column.mid {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.classifier_arrow {
  width: 100%;
  max-width: 2rem;
  height: auto;
  margin: 0.75rem 0;
  stroke-width: 1px;
}
.classifier_arrow svg,
.classifier_arrow path {
}
.classifier_arrow.inactive svg,
.classifier_arrow.inactive path {
  cursor: default;
  fill: #b0b4b4;
}

.classifier_arrow:hover svg,
.classifier_arrow:hover path {
  fill: #39873d;
}
.classifier_arrow.inactive:hover svg,
.classifier_arrow.inactive:hover path {
  cursor: default;
  fill: #b0b4b4;
}
.classifierPopUpButton_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.classifierPopUpButton_wrapper button:first-child {
  margin-right: 2rem;
}

.classifierPopUpButton_wrapper button {
  width: 10rem;
}
.classifier_popUp {
}
.classifier_popUp textarea {
  min-height: 7rem;
}
