.notification_wrapper {
  position: fixed;
  display: flex;
  background: #c8e6c9;
  color: #43a047;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 2rem;
  text-align: center;
}

.notification_wrapper.error {
  color: #f71919 !important;
  background: #ffb3b3;
}
