#overlay-container {
  max-width: max-content !important;
}

.overlay {
  .overlay-container {
    padding: 20px !important;
    overscroll-behavior: contain;

    &.need-scroll {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 1420px) {
  .overlay-container {
    max-height: 100%;
  }
}

@media screen and (max-width: 1220px) {
  .overlay-container {
    max-height: 90%;
  }
}

@media screen and (max-width: 1020px) {
  .overlay-container {
    max-height: 60%;
  }
}
