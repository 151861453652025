.popupframe-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: none;
  z-index: 999;

  &.shown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.popUp_Close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.popupframe-cnt {
  display: none;
  position: absolute;
  background: #ffffff;
  padding: 20px;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.shown {
    display: block;
  }

  .exit-btn {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
}
