.user_form_wrapper {
  background-color: #fff;
  border: 1px solid var(--borderColor);
  padding: 3rem 4.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}
.user_form_record {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 1.25rem;
}
.user_form_record .split {
  display: grid;
  gap: 0 1rem;
  grid-template-columns: repeat(2, 1fr);
}
.user_form_container {
  min-height: 2.875rem;
  max-width: 22rem;
  display: flex;
  --height: 2.875rem;
  --Height: var(--height);
}
.user_form_container.split {
  display: grid;
  gap: 1rem;
}
.user_form_container > div,
.user_form_container button {
  flex: 1;
}
.user_form_container_input_div {
  height: 2.875rem;
}

.user_form_container_input_div > input {
  height: var(--Height);
}
.user_form_container_input_div > .dropD {
  --height: calc(var(--Height) - 2px);
}

.user_form_header {
  font-weight: 500;
  padding-bottom: 0.25rem;
  display: flex;
}

.userActivity_det_link {
  text-decoration: none;
}
