.cover_wrap {
  overflow: hidden;
  position: relative;
  display: block;
}
.cover_wrap:before {
  content: "";
  padding-top: 100%;
  display: block;
}
.cover_wrap .inner {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cover_wrap .inner > div,
.cover_wrap .inner > img,
.cover_wrap .inner > span {
  flex: 1;
}
