.profileMessage {
  font-size: 1rem;
  padding: 1rem 2rem;
}

.profileMessage textarea {
  min-height: 10rem;
}

.profileMessage_button_wrapper {
  padding: 2rem 0;
}
.profileMessage_button_wrapper button {
  margin-right: 2rem;
  min-width: 7rem;
}

.profileMessage_details_edit_wrap {
  max-width: 20rem;
  --shiftSpace: 0.25rem;
  flex-basis: content;
  height: max-content;
  display: flex;
  margin-bottom: 1.4rem;
  align-items: flex-start;
  margin-left: calc(-1 * var(--shiftSpace));
}
.profileMessage_details_edit_wrap.email {
  margin-left: 0;
}
.profileMessage_details_edit_wrap > p,
.profileMessage_details_edit_wrap > span {
  padding-left: var(--shiftSpace);
  margin-right: 1rem;
  margin-bottom: 4px;
}
.profileMessage_details_edit_wrap input {
  resize: none;
  /* border-color: transparent !important; */
  margin-right: 1rem;
  height: 40px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: -0.25rem;
  padding: 0;
  padding: calc(var(--shiftSpace) - 1px);
}
.profileMessage_details_edit_wrap input {
  height: auto !important;
  text-align: left;
  border: 1px solid var(--borderLteColor);
}
