.newConfirmDialog_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  padding: 3rem;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  text-align: center;
}
.newConfirmDialog_wrap.show {
  display: flex;
}

.newConfirmDialog_container {
  max-width: 35rem;
  min-width: 30rem;
  padding: 4.5rem;
  position: relative;
  background-color: #fff;
  overscroll-behavior: contain;
  overflow-y: auto;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.newConfirmDialog_container .close_container {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1rem;
}
.newConfirmDialog_container .close_container:hover {
  opacity: 0.6;
}

.newConfirmDialog_container p {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
}
.newConfirmDialog_btn_container {
  padding: 1rem;
  display: flex;
  gap: 1.5rem;
}

.newConfirmDialog_btn_container button {
  height: 3rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newConfirmDialog_btn_container button span {
  height: 2rem;
  width: 2rem;
  margin-left: 1rem;
}
