.disposal-form {
  width: 1400px;

  .disposalForm_btn_wrapper {
    gap: 2rem;
    padding: 2rem;
  }

  .disposalForm_btn_wrapper button {
    min-width: 12rem;
  }

  h4 {
    display: inline;
    margin-right: 30px;
    text-transform: uppercase;
  }

  .btn-action {
    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  .DATASET__form {
    &.first {
      margin: 30px 0 0 0 !important;
      grid-template-columns: 25% auto 25% 10% 10% 220px;
      border-bottom: 0 !important;
    }

    &.second {
      margin: 0 0 0 0 !important;
      grid-template-columns: 35% 35% 15% 15%;
    }

    &.list {
      margin: 30px 0 0 0 !important;
      grid-template-columns: 25% 25% 20% 20% 10%;
    }

    textarea {
      width: 100%;
    }
  }

  .DATASET__data {
    border: 1px solid #9f9f9f;
    display: grid;
    grid-template-columns: 25% 25% 20% 20% 10%;

    .DATASET__form-input {
      margin: 0 !important;
      padding: 15px;

      &.brd-bottom {
        border-bottom: 1px solid #9f9f9f;
      }

      &.hover {
        background-color: #fffaee;
      }
    }
  }
}

@media screen and (max-width: 1420px) {
  .disposal-form {
    width: 1200px;
  }
}

@media screen and (max-width: 1220px) {
  .disposal-form {
    width: 900px;

    .DATASET__form {
      &.first {
        grid-template-columns: 20% auto 25% 10% 10% 200px;
      }

      &.second {
        grid-template-columns: 35% 35% 15% 15%;
      }

      &.list {
        grid-template-columns: 25% auto 20% 10% 16%;
      }
    }

    .DATASET__data {
      grid-template-columns: 25% auto 20% 10% 16%;
    }
  }
}

@media screen and (max-width: 1020px) {
  .disposal-form {
    width: 800px;
  }
}
