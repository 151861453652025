.serija_wrapper {
  max-width: 60rem;
}
.seria_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  white-space: normal;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  border-radius: 0;
  min-width: 80px;
  border-radius: var(--rad);
  cursor: pointer;
}

.seria_btn.btn-disabled {
  cursor: not-allowed;
}

.seria_site_container {
  margin-top: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
}
