.formation {
  margin-top: 10px;

  .HOMEPAGE__section-custom {
    align-items: center !important;
    padding: 40px;
    display: flex;
    justify-content: flex-start;

    .nav-link {
      display: flex !important;
      justify-content: center;
    }
  }
  .DATASET__form-custom {
    position: relative;
    display: grid;
    grid-template-columns: 20% 380px 20% 10% 20% auto;

    .DATASET__form-title {
      border-bottom: 1px solid #9f9f9f;
      font-size: 16px;
      padding: 20px !important;
    }

    .DATASET__form-input {
      &.DROPDOWN {
        &:after {
          top: 20%;
          right: 4%;
        }
      }

      .range-datepicker-wrapper {
        display: flex;
        flex-direction: row;

        .datetimepicker-wrapper:first-child {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }

  .DATASET__data {
    position: relative;
    display: grid;
    grid-template-columns: 20% 380px 20% 10% 20% auto;

    .DATASET__form-input {
      border-top: 1px solid #9f9f9f;
      margin: 0 !important;
      padding: 15px;
    }
  }

  .DATASET__header-custom {
    position: relative;
  }

  .DATASET__wrapper {
    border: 1px solid #999999;
    background-color: #ffffff !important;

    .DATASET__form-custom {
      position: relative;
      display: grid;
      grid-template-columns: 20% 380px 20% 10% 20% auto;

      .DATASET__form-title {
        border-bottom: 1px solid #9f9f9f;
        font-size: 16px;
        padding: 20px !important;
      }

      .DATASET__form-input {
        &.DROPDOWN {
          &:after {
            top: 20%;
            right: 4%;
          }
        }
      }
    }

    .DATASET__data {
      position: relative;
      display: grid;
      grid-template-columns: 20% 380px 20% 10% 20% auto;

      .DATASET__form-input {
        border-top: 1px solid #9f9f9f;
        margin: 0 !important;
        padding: 15px;

        &.hover {
          background-color: #fffaee;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .DATASET__form-input input,
  .DATASET__form-input select {
    height: 33px !important;
  }

  .DATASET__form-input .multi-dropdown-wrapper:after {
    width: 24px;
    height: calc(100% - 2px);
  }
}

@media screen and (max-width: 1399px) {
  .DATASET__form-input input,
  .DATASET__form-input select {
    height: 35px !important;
  }

  .DATASET__form-input .multi-dropdown-wrapper:after {
    height: 33px;
    width: 30px;
  }
}

@media screen and (max-width: 1680px) {
  .formation {
    .DATASET__wrapper {
      overflow-x: scroll;
      overflow-y: hidden;

      .DATASET__form-custom {
        grid-template-columns: 20% 380px 25% 10% 20% 5%;
      }

      .DATASET__data {
        grid-template-columns: 20% 380px 25% 10% 20% 5%;
      }
    }
  }
}
