.site-selection {
  .site-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem 2rem;
    margin: 0 auto;
    gap: 0.5rem;
  }

  label {
    display: block;
    border: 1px solid #9f9f9f !important;
    border-radius: 2px;
    position: relative;
    padding: 5px 10px;
    background: #fff;
    color: #2a2a2a;
    z-index: 12;
    margin-bottom: 0;
  }

  input[type="checkbox"] {
    appearance: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    left: 0;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    border: none !important;

    &:checked {
      background: #43a047;
      opacity: 0.3;
      color: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .actions {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      &:nth-child(1) {
        margin-right: 5px;
      }

      &:nth-child(2) {
        margin-left: 5px;
      }
    }
  }
}
