.disposalForm_wrapper {
  --height: 40px;
  --top: 3.5rem;
  --left: 2rem;
  --fontRed: #f02222;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}
.disposalForm_wrapper h4 {
  display: inline;
  margin-right: 20px;
}
.disposalForm_wrapper .waste_create_container {
  padding: 1.5rem;
}
.disposalForm_wrapper .waste_create_container_header {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  height: auto;
}
.disposalForm_table_holder > div,
.disposalForm_table_holder > div > div {
  height: 100%;
}
.licenceInput_wrap + .datetimepicker-wrapper input {
  height: calc(var(--height) + 2px);
}
.disposalAmount_table_wrap {
  margin-top: 1rem;
}
.disposalForm_wrapper p {
  flex: 1 1;
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0 2rem 0 0;
}
.disposalForm_btn_wrapper {
  gap: 2rem;
  padding: 2rem;
}

.disposalForm_btn_wrapper button {
  min-width: 12rem;
}
.alignRight_disposal_input input {
  text-align: right;
}
.dispAmtBtn:hover,
.dispAmtBtn:focus,
.dispAmtBtn {
  min-width: 6.5rem !important;
  outline: none !important;
}
