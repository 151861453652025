.profileRecords_wrapper {
  margin-top: var(--mTop);
}
.profileRecords_header {
  margin-bottom: 1.125rem;
  display: flex;
  align-items: center;
  --height: 2.25rem;
}
.profileRecords_header p {
  flex: 1;
  font-weight: 600;
  margin: 0;
  font-size: 1.125rem;
  margin-right: 2rem;
}
.profileRecords_btn_wrapper {
  display: flex;
  justify-content: center;
}
.profileRecords_btn_wrapper button {
  padding: 0.375rem 2rem;
}
.profileRecords_header_dropD_container {
  width: 9.5rem;
}
.profileRecords_header_dropD_container.limitia_sites {
  width: 15rem;
}
.profileRecords_wrapper .form_management_wrapper {
  margin-bottom: 0rem;
}

.profileRecord_dec_link {
  color: rgb(26, 115, 232);
  overflow-wrap: anywhere;
  min-width: 0;
  text-decoration: underline;
  text-align: center;
}
.profileRecord_dec_link:hover {
  color: rgb(24, 101, 201);
}
