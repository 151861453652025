.user-form {
  margin: 40px 0 0 0;

  .cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .col.pad-left {
      padding-left: 10px;
      padding-right: 0;
    }

    .col.pad-right {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  .actions {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      &:nth-child(1) {
        margin-right: 10px;
      }

      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}
