.waste_create_wrapper {
  --height: 40px;
  --top: 3.5rem;
  --left: 2rem;
  --fontRed: #f02222;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;
}
.waste_create_freeLimit_input_wrapper,
.waste_unit_input_wrapper {
  display: flex;
  align-items: stretch;
  align-self: stretch;
}

.waste_create_freeLimit_input_wrapper input,
.waste_unit_input_wrapper input {
  height: 100%;
}
.waste_create_freeLimit_input_wrapper > div,
.waste_unit_input_wrapper > div {
  flex: 1;
}

button.wasteCreate_freeLimitCheck {
  word-wrap: anywhere;
  align-self: stretch;
  border-radius: 2px;
  font-weight: 500;
  border: 1px solid var(--borderColor) !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.waste_create_container {
  width: 100%;
  min-height: 100%;
  z-index: 9;
  overscroll-behavior: contain;
  padding: var(--top) 1.5rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  position: relative;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.waste_create_container_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  position: absolute;
  height: 3.5rem;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  font-weight: 700;
  margin-bottom: 1rem;
}

p.wasteCreate_para {
  font-size: 1.25rem;
  font-weight: 700;
}
.waste_create_container_header > p.wasteCreate_para {
  margin: 0;
}

.waste_create_det_wrapper {
  margin-bottom: 2rem;
}
.waste_create_det_wrapper .fgHead {
  font-size: 1rem;
}

.waste_site_select_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

button.waste_site_select_item {
  min-width: 0;
  border: 1px solid var(--borderColor) !important;
  padding: 0.5rem;
  border-radius: 2px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  transition: all 100ms ease;
}

button.waste_site_select_item:hover:not([disabled]),
button.wasteCreate_freeLimitCheck:hover {
  background-color: #f2f2f2;
}

.wasteCreate_freeLimitCheck.limited:hover,
.wasteCreate_freeLimitCheck.limited,
.waste_site_select_item.selected:hover:not([disabled]),
.waste_site_select_item.selected {
  background-color: rgb(199, 227, 200);
}

.waste_site_select_item[disabled] {
  background-color: #e1e1e1;
}

.accpt_waste_removal_btn_wrapper.wasteCreateCheck {
  justify-content: center;
  display: flex;
}
.waste_create_error {
  font-size: 0.85rem;
  color: var(--fontRed);
}

.unit_waste_creation_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
