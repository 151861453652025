.documentNr_accpt_wrapper div.lt_wrapper {
  width: 1.5rem;
  padding: 0 0.25rem;
  background-color: #f2f2f2;
  transition: all 200ms ease;
  align-self: stretch;
  border-right: 1px solid #9f9f9f;
}
.documentNr_accpt_wrapper.ltLogo {
  border: 1px solid var(--borderColor);
}
.documentNr_accpt_wrapper.ltLogo .input_bound_wrapper {
  border: none !important;
}
.waste_person_filter {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  height: 0rem;
  overflow: visible;
  z-index: 999;
}

.waste_person_filter > .waste_person_filter_container {
  padding: 0 var(--padding);
}

.waste_person_filter > .waste_person_filter_container > .inner {
  background-color: rgb(255, 255, 255);
  height: fit-content;
  max-height: 20rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: var(--rad);
  overflow-y: auto;
  width: fit-content;
  position: relative;
  width: 40%;
  max-width: 50%;
  border: 1px solid #e5e5e5;
}

.close_person_filter {
  background-color: #fff;
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
  --size: 1.1rem;
  padding: 0.15rem;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
}

.waste_person_filter
  > .waste_person_filter_container
  > .inner
  > div.filter_item {
  padding: 0.75rem var(--padding);
  cursor: pointer;
}
.waste_person_filter
  > .waste_person_filter_container
  > .inner
  > div.filter_item:hover {
  background-color: #eee;
}
