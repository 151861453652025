.addNewWaste_wrapper {
  display: flex;
  margin: 2.5rem 0;
  gap: 2.5rem;
}

.setting_new_item_wrapper {
  padding: 0 2rem;
  padding-top: 1rem;
}

.addNewSerija,
.addNewWaste {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: rgb(234, 234, 234);
  padding: 0.25rem 0.5rem;
  border-radius: var(--rad);
  transition: all 200ms ease;
}

.addNewSerija:hover,
.addNewWaste:hover {
  background-color: rgb(225, 225, 225);
}
.addNewSerija div,
.addNewWaste div {
  display: flex;
  align-items: center;
}

.setting_create_button_wrapper {
  gap: 2rem;
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
}

.setting_create_button_wrapper > button {
  padding: 0.375rem 2rem;
  min-width: 12rem;
  overflow-wrap: anywhere;
}
