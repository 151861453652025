@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .searchbox-wrapper {
    input {
      min-width: 80px;
      max-width: 100%;
      background: url(/assets/images/lens.png) no-repeat 10px 10px #ffffff;
      background-size: 24px 24px;
      padding: 0 0 0 40px !important;
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .searchbox-wrapper {
    input {
      min-width: 80px;
      max-width: 100%;
      background: url(/assets/images/lens.png) no-repeat 8px 8px #ffffff;
      background-size: 18px 18px;
      padding: 0 0 0 26px !important;
    }
  }
}

@include media-breakpoint-down(lg) {
  .searchbox-wrapper {
    input {
      min-width: 80px;
      max-width: 100%;
      background: url(/assets/images/lens.png) no-repeat 8px 8px #ffffff;
      background-size: 18px 18px;
      padding: 0 0 0 26px !important;
    }
  }
}
