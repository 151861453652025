.xlsx_button {
  display: inline-flex;
  width: 35px;
  border: none;
  padding: 0;
}
.xlsx_button[disabled] {
  pointer-events: none;
  opacity: 0.4;
}
