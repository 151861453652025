.waste_management_form {
  overflow-y: auto;
  /* overflow-x: hidden; */
  padding: 0 var(--left);
}
.waste_management_container {
  border-radius: 0.125rem;
  border: 1px solid var(--borderColor);
  margin-bottom: 1.25rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.waste_management_container:first-child {
  margin-top: 1.25rem;
}
.extra_waste_del_icon_wrapper {
  align-self: stretch;
}
.pageButtons {
  /* This var(--left) is declared in NewPopUpFrame.css */
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  /* padding top is set to 1rem because each container has a margin button of 1rem */
  padding-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.pageButtons.hasError {
  color: #fd4b0d;
}
.pageButtons.hasError svg g {
  fill: #fd4b0d;
}

.pageBtn {
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
}
.pageBtn:hover {
  background-color: #eee;
}
.pageBtn.selected {
  opacity: 0.3;
  pointer-events: none;
}
.pageBtn button {
  height: 1.25rem;
  padding: 0.25rem 0;
}
.pageBtn button:hover > img {
  /* Other responsive behaviour css set in the NewPopUpFrame css sheet */
  opacity: 0.7;
}
.pageBtn span {
  font-size: 0.85rem;
  flex: 1;
  font-weight: 700;
  padding-left: 0.5rem;
}
.modal_button_container {
  gap: 2rem;
  padding: 3rem;
}
.modal_btn {
  min-width: 12rem;
  min-height: 2.75rem;
  padding: 0.5rem 4rem;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.125rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 6px,
    rgba(17, 17, 26, 0.05) 0px 3px 14px;
}
.modal_btn:last-child {
  border: none;
  background-color: #43a047;
  color: #fff;
}
.modal_btn:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.wasteManagement_row_btn_wrapper {
  height: 100%;
  width: 100%;
}
.wasteManagement_head_btn {
  display: flex;
}
.wasteManagement_head_btn > div {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  background-color: #fff;
  overflow: hidden;
  font-weight: 600;
}
.wasteManagement_head_btn > div.selected {
  color: #fff;
  background-color: #43a047;
}
.wasteManagement_head_btn > div.vol_disabled {
  pointer-events: none;
  opacity: 0.3;
}
.wasteManagement_head_btn > div > button {
  text-align: center;
  padding: 0 0.25rem;
}

.wasteAccum_volume_value_wrapper {
  display: flex;
  align-items: stretch;
  align-self: stretch;
}
.wasteAccum_volume_value_wrapper > div {
  width: 100%;
}

.wasteAccum_volume_value_wrapper input {
  height: 100%;
}
