.download_type_selector_wrapper {
  gap: 0.5rem;
}
.download_type_selector_wrapper.blur {
  color: #dadada;
}
.download_calendar_wrapper {
  margin: 1.5rem 0;
}
.download_calendar_wrapper.no_visi {
  visibility: hidden;
}
.download_button_wrapper {
  padding-top: 5rem;
  display: flex;
  justify-content: flex-end;
}
.download_button_wrapper > button {
  min-width: 10rem;
}
