@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .input-text {
    width: 100%;
    height: 45px !important;
  }
}

@include media-breakpoint-between(lg, xxl) {
  .input-text {
    width: 100%;
    height: 35px !important;
  }
}

@include media-breakpoint-down(lg) {
  .input-text {
    width: 100%;
    height: 33px !important;
  }
}
