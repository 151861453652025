@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .disposal-journal {
    margin-top: 10px;
    margin-bottom: 300px;

    .DATASET {
      .DATASET__wrapper {
        margin-bottom: 40px;

        .DATASET__form {
          margin: unset !important;

          &.grid1 {
            grid-template-columns: 320px 11% 11% 11% 11% 11% 11% auto;
          }

          .DATASET__form-title {
            text-align: unset !important;
          }
        }

        .DATASET__data {
          margin: unset !important;

          &.grid1 {
            grid-template-columns: 150px 16% 15% 11% 11% 31% auto;
            background-color: #ffffff;
          }

          &.grid2 {
            grid-template-columns: 40% 20%;
            background-color: #ffffff;
          }

          &.data-collapse {
            display: none;
          }
        }
      }
    }

    .btn-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .btn-cancel {
        width: 100%;
        margin: 0 0 10px 0;
      }

      .btn-completed {
        width: 100%;
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .disposal-journal {
    margin-top: 10px;
    margin-bottom: 300px;

    .DATASET {
      .DATASET__wrapper {
        margin-bottom: 40px;

        .DATASET__form {
          margin: unset !important;

          &.grid1 {
            grid-template-columns: 220px 11% 11% 11% 11% 11% 80px auto;
          }

          .DATASET__form-title {
            text-align: unset !important;
          }
        }

        .DATASET__data {
          margin: unset !important;

          &.grid1 {
            grid-template-columns: 220px 11% 11% 11% 11% 11% 80px auto;
            background-color: #ffffff;
          }

          &.grid2 {
            grid-template-columns: 40% 20%;
            background-color: #ffffff;
          }

          &.data-collapse {
            display: none;
          }
        }
      }
    }

    .btn-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .btn-cancel {
        width: 100%;
        margin: 0 0 10px 0;
      }

      .btn-completed {
        width: 100%;
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .disposal-journal {
    margin-top: 10px;
    margin-bottom: 300px;

    .DATASET {
      .DATASET__wrapper {
        margin-bottom: 40px;

        .DATASET__form {
          margin: unset !important;

          &.grid1 {
            grid-template-columns: 15% 11% 11% 11% 11% 11% 12% auto;
          }

          .DATASET__form-title {
            text-align: unset !important;
          }
        }

        .DATASET__data {
          margin: unset !important;

          &.grid1 {
            grid-template-columns: 15% 11% 11% 11% 11% 11% 12% auto;
            background-color: #ffffff;
          }

          &.grid2 {
            grid-template-columns: 40% 20%;
            background-color: #ffffff;
          }

          &.data-collapse {
            display: none;
          }
        }
      }
    }

    .btn-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .btn-cancel {
        width: 100%;
        margin: 0 0 10px 0;
      }

      .btn-completed {
        width: 100%;
        margin: 0;
      }
    }
  }
}
