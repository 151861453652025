.limitia_box_tab.waste_coming_tab {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
}

.limitia_box_tab.waste_coming_tab .limitia_box {
  padding: 0;
  align-items: center;
}

.waste_coming_tab .btn.btn-green,
.waste_coming_tab .btn.btn-cancel,
.waste_coming_tab .btn.btn-orange {
  color: #fff;
  width: 168px;
}

.waste_coming_tab .btn.btn-cancel {
  background: #FD4B1A;
  margin-left: 22px;
}