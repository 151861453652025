.sorter_wrapper {
  position: relative;
  flex: 1;
  border: none;
  cursor: pointer;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: var(--rad);
}
.sorter_wrapper:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}
.sorter_container {
  background-color: #fff;
  display: flex;
  border-radius: var(--rad);
  overflow: hidden;
}

.sorter_title {
  border-radius: 0 !important;
  height: var(--height);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.sorter_btn {
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  border: 1px solid #9f9f9f;
  border-radius: inherit;
  transition: all 200ms ease;
}
.sorter_btn:hover {
  background-color: #e5e5e5;
}
.sorter_btn > div {
  width: 1rem;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
}
.sorter_btn.rotate > div {
  transform: rotateZ(180deg);
}
.sorter_btn svg path {
  stroke: #2a2a2a;
  stroke-width: 0.6px;
}
