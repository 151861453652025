.user-profile-bg {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 9999;
}

.user-profile {
  color: #ffffff;
  font-weight: bold;
}

.user-profile .user-name {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
}
.user-profile .user-name .bg-arrow {
  display: inline-block;
  width: 40px;
  height: 10px;
}

.user-profile .user-name .bg-arrow.up {
  background: url(../../../assets/images/down-arrow-w.svg) no-repeat;
  transform: rotate(180deg);
  background-position-x: 25px;
}

.user-profile .user-name .bg-arrow.down {
  background: url(../../../assets/images/down-arrow-w.svg) no-repeat;
}

.user-profile .user-drop-down {
  position: absolute;
  background-color: #ffffff;
  margin: 10px 0 0 0;
  padding: 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  z-index: 99999;
  width: 200px;
  border: 1px solid #9f9f9f;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}

.user-drop-down.hide {
  display: none;
}
.user-drop-down li {
  width: 100%;
  color: #2a2a2a;
  border-radius: 0 !important;
}
.user-drop-down li svg,
.user-drop-down li svg path {
  fill: #2a2a2a !important;
}
.user-drop-down li:hover svg,
.user-drop-down li:hover svg path {
  fill: #fff !important;
}
.user-drop-down li:hover {
  background-color: #43a047;
  color: #fff;
}
.user-drop-down li .user_flex {
  color: #2a2a2a;
  display: flex;
}
.user-drop-down li .user_flex:hover {
  color: #fff;
  display: flex;
}
.user_drop_icon {
  margin-right: 10px;
}
.user_drop_text {
  display: flex;
  align-items: center;
}
