.sig_wrapper {
  position: relative;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.sig_container_wrapper {
  width: 100%;
}

.sigContainer {
  width: 100%;
  height: 15rem;
  border: 2px dashed #c8c8c8;
  border-radius: var(--rad);
}

.sigContainer > canvas {
  width: 100%;
  height: 100%;
}

.signature_button_wrapper {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  justify-content: center;
}
.signature_button_wrapper button {
  padding: 0.375rem 2rem;
  min-width: 12rem;
  overflow-wrap: anywhere;
}

.sig_image {
  background-color: #fff;
}

.close_sig_wrapper {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 0.75rem;
  width: 0.75rem;
}

.sign_pic_wrapper {
  height: 100%;
}

.open_sig_wrapper {
  padding-top: 0.5rem;
  width: 100%;
}

.open_sig_wrapper > button {
  margin: 0 auto;
  padding: 0.125rem 0.25rem;
  font-size: 0.85rem;
  width: 100%;
  background-color: rgb(234, 234, 234);
  border-radius: var(--rad);
}
.open_sig_wrapper > button:hover {
  background-color: rgb(225, 225, 225);
}
