.settings {
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 0 var(--genSidePadding);
  overflow-x: unset !important;

  .HOMEPAGE__section {
    display: flex;
  }

  .DATASET__form {
    &.custom-settings {
      .DATASET__form-input {
        margin: 10px 10px 0 0;
      }

      .DATASET__form-input-checkboxes {
        margin: 10px 5px 0 5px;

        label,
        input {
          height: 45px;
          margin-bottom: 10px;
        }

        &:last-child {
          margin: 10px 10px 0 5px;
        }
      }
    }
  }

  .DATASET__form-input-checkboxes {
    &.grid-6 {
      label {
        margin: 5px;
      }
    }
  }

  .tab-nav {
    ul {
      padding: 0;
      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      li {
        display: inline-block;
        text-align: center;
        margin-right: 40px;
        flex: 1;

        &:last-child {
          margin-right: 0;
        }

        a {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border: none;
          border-radius: 4px;
          padding: 1rem;
          color: #ffffff;
          // width: 240px;

          font-weight: bold;
          font-size: 18px;
          text-decoration: none;
          color: #000000;

          &:hover {
            color: #000000;
          }

          &.tab-link-active {
            color: #ffffff;
            background-color: #43a047;
          }
        }
      }
    }
  }

  .tab-contents {
    .tab-content {
      display: none;

      &.tab-show {
        display: block;
      }

      .tab-content-header {
        display: grid;
        grid-template-columns: 25% auto auto;
      }

      .DATASET__wrapper {
        margin: 40px 0 0 0;

        &.cnt1 {
          .DATASET__form-custom {
            grid-template-columns: 10% auto 12% 8% 8% 8% 8% 12%;
          }

          .DATASET__data {
            .wastes-wrapper {
              grid-template-columns: 10% auto 12% 8% 8% 8% 8% 12%;
            }
          }
        }

        &.cnt2 {
          .DATASET__form-custom {
            grid-template-columns: 10% auto 12% 10% 10% 10% 12%;
          }

          .DATASET__data {
            .wastes-wrapper {
              grid-template-columns: 10% auto 12% 10% 10% 10% 12%;
            }
          }
        }

        .DATASET__form-custom {
          border: 1px solid #999999;
          display: grid;
        }

        .DATASET__data {
          .group-wrapper {
            display: grid;
            grid-template-columns: 50% 50%;
          }

          .wastes-wrapper {
            border: 1px solid #999999;
            display: grid;

            .DATASET__form-static {
              border-bottom: 1px solid #999999;

              input {
                height: 45px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1281px) {
  .settings {
    .DATASET__form {
      &.custom-settings {
        margin: 0 0 40px 0 !important;
        display: grid;
        grid-template-columns: 10% 10% auto 6% 6% 6% auto;

        .DATASET__form-input-checkboxes {
          .input-number {
            width: 80px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .settings {
    .DATASET__form {
      &.custom-settings {
        margin: unset !important;
        display: grid;
        grid-template-columns: 10% auto 14% 10% 10% 10% 22%;

        .DATASET__form-input-checkboxes {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }
      }
    }

    .DATASET__form-input-checkboxes {
      &.grid-6 {
        label {
          margin: 3px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .settings {
    .DATASET__form {
      &.custom-settings {
        margin: unset !important;
        display: grid;
        grid-template-columns: 10% auto 14% 8% 8% 8% 28%;

        .DATASET__form-input-checkboxes {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }
      }
    }

    .DATASET__form-input-checkboxes {
      &.grid-6 {
        label {
          margin: 3px;
        }
      }
    }
  }
}
