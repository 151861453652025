.siteLimit_drop_wrapper {
  width: 100%;
  max-width: 21rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2.5rem;
}

.disableCheckSiteWaste {
  pointer-events: none;
  /* opacity: 0.75; */
}

.accpt_waste_removal_btn_wrapper.stWastesCheck {
  justify-content: center;
  display: flex;
}

.siteLimit_drop_wrapper > p {
  margin: 0;
}

.groupWaste_name_wrapper p {
  font-weight: bolder;
  padding: 1rem;
  margin: 0;
}

.groupWaste_header {
  margin-bottom: 2rem;
}

.site_limit_error_wrapper {
  grid-column: 4/7;
  position: relative;
  height: 0;
  overflow-y: visible;
  background-color: #000;
}
.site_limit_error_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  position: absolute;
  --color: #fff;
  background-color: var(--color);
  padding: 0.85rem;
  max-width: 100%;
  width: 100%;
  border-radius: var(--rad);
  opacity: 1 !important;
  z-index: 99;
  height: max-content;
  max-height: 20rem;
  overflow-y: auto;
  justify-self: center;
  display: flex;
  flex-direction: column;
  --borderSize: 1px;
  transform-style: preserve-3d;
  --borderColor: #ddd;
  border: var(--borderSize) solid var(--borderColor);
  --box-shadow: rgba(143, 143, 143, 0.12) 0px 2px 3px 0px;
  box-shadow: var(--box-shadow);
}
.site_limit_error_container > p {
  font-weight: 600;
}
.site_limit_error_container > div {
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: var(--borderSize) solid #eee;
}
.site_limit_error_container > div:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.site_waste_table:first-child {
  margin-top: 1.5rem;
}
.site_waste_table {
  margin-bottom: 3rem;
}
.site_waste_table .fgHead {
  font-size: 1rem;
}
.groupHead_text_wrapper {
  align-items: center;
  display: flex;
}
.groupHead_input_wrapper {
  align-items: center;
  gap: 1rem;
  max-width: 30%;
  display: flex;
  justify-content: flex-end;
}
.groupHead_input_wrapper .input_bound_wrapper {
  width: 7.5rem;
}

.site_waste_edit_group_fl {
  width: 0.85rem;
}
.site_waste_edit_group_fl.inactive {
  display: none;
}
.site_waste_edit_group_fl.tick path {
  fill: var(--vaatcGreen);
  stroke: transparent;
}
.groupHead_text_wrapper.editing
  .input_icon_btn
  .site_waste_edit_group_fl.tick
  path,
.groupHead_input_wrapper
  .input_icon_btn:hover
  .site_waste_edit_group_fl.tick
  path {
  fill: #fff;
}

.groupHead_text_wrapper.editing .input_icon_btn {
  background-color: var(--vaatcGreen);
}

.hideSiteEdit {
  height: 0 !important;
  width: 0 !important;
  display: none !important;
}
