.formGenWrapper {
  --redColor: #e5e5e5;
  --btnColor: #f2f2f2;
  --input_height: 40px;
  border-bottom: 1px solid var(--borderColor);
  width: fit-content;
  min-width: 100%;
}
.formGenWrapper:last-child {
  border-bottom: none;
}
.fgHeader:last-child {
  border-bottom: none;
}
.fgHead,
.fgRow {
  display: grid;
  align-items: flex-start;
  min-width: 0;
  gap: 0 1rem;
  padding: 1.25rem 1.5rem;
  background-color: #fff;
}
.fgRow > div {
  min-width: 0;
}
.fgRow .range-datepicker-wrapper {
  flex-direction: row;
}
.fgHead {
  background-color: #c8e6c9;
  border-bottom: 1px solid var(--borderColor);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  z-index: 2;
}
.fgHead.noBorder {
  border: none;
}
.fgHead > div {
  text-align: center;
  overflow-wrap: anywhere;
}
.fgHead > .alignLeft {
  text-align: left;
}
.fgFieldInputWrap {
  min-width: 0;
}
.fgFieldTextWrap {
  /* word-wrap: break-word; */
  overflow-wrap: anywhere;
  color: #2a2a2a;
  min-width: 0;
}
.fgFieldTextWrap.center {
  text-align: center;
}
.fgFieldInputWrap .top,
.fgFieldInputWrap .bottom {
  display: flex;
}
.input_wrap_date {
  display: block !important;
  align-self: stretch;
}
.fgFieldInputWrap .bottom,
.fgFieldInputWrap .input_wrap_date {
  display: flex;
  border-radius: 3px;
}
.fgFieldInputWrap .input_wrap_date {
  border: 1px solid var(--borderColor);
}
.fgFieldInputWrap .input_wrap_date input {
  border: none !important;
}
.fgBInput {
  height: var(--height);
}
.fgFieldInputWrap .fgBInput {
  padding: 0.75rem;
  height: var(--input_height);
  border: none !important;
}
.fgFieldInputWrap input {
  height: var(--input_height) !important;
}

.fgFieldInputWrap input::placeholder,
.fgBInput::placeholder {
  font-size: 0.9rem;
  color: rgb(159, 159, 159);
}

.fgFieldInputWrap .input_wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.fgFieldInputWrap .input_wrap input {
  flex: 1;
}

.fgFieldInputWrap .fgBInput::webkit-calendar-picker-indicator {
  display: none;
}

.fgFieldInputWrap .fgBtn {
  width: 2.5rem;
  background-color: var(--btnColor);
  border-left: 1px solid var(--borderColor);
}

.fgBtnCont {
  /* Change this if you need to increase icon size */
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transition: all 200ms ease;
}

.fgBtnCont:hover {
  opacity: 0.7;
}

.fgHead_btn {
  display: grid;
}
.fgHead_btn > div {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  background-color: #fff;
  overflow: hidden;
  font-weight: 600;
}
.fgHead_btn > div.selected {
  color: #fff;
  background-color: #43a047;
}
.fgHead_btn > div > button {
  text-align: center;
  padding: 0 0.25rem;
}

/* Input Date Component */

.dateField_wrapper {
  display: flex;
  border: 1px solid var(--borderColor);
  border-radius: var(--rad);
  overflow: hidden;
}

.dateField_wrapper input {
  border: none !important;
  border-radius: 0 !important;
  cursor: pointer;
  height: auto;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.dateField_wrapper input::placeholder {
  font-size: 0.9rem;
  color: rgb(159, 159, 159);
}
.dateField_wrapper input:focus {
  border: none;
}

.dateField_btn {
  position: relative;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  border-left: 1px solid var(--borderColor);
  transition: all 200ms ease;
  margin: 0;
}

.dateField_wrapper:hover .dateField_btn,
.dateField_btn:hover {
  background-color: #e5e5e5;
}

.dateField_btn > div {
  width: 1rem;
  transition: all 150ms ease;
}

.fgSearch_container {
  position: relative;
}

.fgSearch_wrapper {
  display: flex;
  border: 1px solid var(--borderColor);
  border-radius: var(--rad);
  overflow: hidden;
}
.fgSearch_wrapper.dropFlip {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.fgSearch_wrapper.drop {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.fgSearch_wrapper input {
  border: none !important;
  border-radius: 0 !important;
  height: auto;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding-left: 0;
}
.fgSearch_wrapper input::placeholder {
  font-size: 0.9rem;
  color: rgb(159, 159, 159);
}
.fgSearch_wrapper input:focus {
  border: none;
}

.fgSearch_btn {
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #ffff;
  transition: all 200ms ease;
}
.fgSearch_btn:hover {
  background-color: #e5e5e5;
}

.fgSearch_btn > div {
  width: 1rem;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
}

.fgSearch_btn svg,
.fgSearch_btn line,
.fgSearch_btn circle {
  stroke: #9f9f9f;
  fill: none;
}
.fgSearch_btn svg:hover {
  opacity: 0.85;
}

.form_management_wrapper {
  overflow-y: visible;
  height: max-content;
  margin-bottom: 1.25rem;
}
.form_management_wrapper.noHeaderBorder .fgHead {
  border-bottom: none;
}

.form_management_container {
  border-radius: 0.125rem;
  border: 1px solid var(--borderColor);
  /* width: fit-content; supply extra info for this */
  min-width: 100%;
  height: max-content;
  overflow-y: visible;
  background-color: #fff;
  width: -moz-fit-content;
  /* margin-bottom: 1.25rem; */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.form_management_container.overflow {
  overflow-x: auto;
}

.form_management_container .formGenWrapper:last-child {
  border-bottom: none;
}

@media (max-width: 1080px) {
  .fgHead {
    font-size: 1rem;
  }
}
.input_bound_wrapper {
  display: flex;
  border: 1px solid #9f9f9f;
  border-radius: var(--rad);
  overflow: hidden;
}
.input_bound_wrapper .fgBInput {
  border: none !important;
}

.input_icon_btn {
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  border-left: 1px solid #9f9f9f;
  transition: all 200ms ease;
}
.input_icon_btn:hover {
  background-color: #e5e5e5;
}

.input_icon_btn > div {
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
}
.input_icon_btn > div > * {
  flex-shrink: 0;
}
