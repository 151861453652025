@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

.home-wrapper {
  min-height: 100vh;
}
@include media-breakpoint-up(xxl) {
  .home-wrapper {
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .HOMEPAGE__section {
      display: flex;
    }

    .site-selection-wrapper {
      width: map-get($container-max-widths, "sm");
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .home-wrapper {
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .HOMEPAGE__section {
      display: flex;
    }

    .site-selection-wrapper {
      width: map-get($container-max-widths, "sm");
    }
  }
}

@include media-breakpoint-down(lg) {
  .home-wrapper {
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .HOMEPAGE__section {
      display: flex;
    }

    .site-selection-wrapper {
      width: map-get($container-max-widths, "sm");
    }
  }
}
