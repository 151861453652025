.user_list {
  margin-top: 40px;
}
.user_list .formGenWrapper {
  border-bottom: 1px solid var(--borderColor);
}
.user_list .formGenWrapper:first-child {
  border-bottom: 1px solid var(--borderColor);
}
.userList_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.userList_btn {
  flex: 1;
  display: inline-flex;
  cursor: pointer;
  min-height: 2.5rem;
  min-width: 0.5rem;
  padding: 0.5rem;
  font-weight: bold;
  border: none;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  border-radius: 0;
  border-radius: 3px;
  font-size: 0.9rem;
}
.userList_range_wrapper {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
}
.userList_range_wrapper input {
  height: 42px;
}
.userList_range_wrapper > div {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}
.userList_range_wrapper .datetimepicker-wrapper:first-child,
.userList_range_wrapper .datetimepicker-wrapper {
  margin: 0;
}
