.feature-permission {
  & > div {
    padding: 0 2rem;
  }
  .profile-summary {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .profile_info_perm {
      display: block;
      padding: 10px;
      min-width: 220px;

      span {
        font-weight: bold;
      }
    }

    .site-select {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      .dropdown-wrapper {
        &:after {
          top: 11px;
          right: 11px;
        }
      }
    }
  }

  .feature-lists {
    margin-top: 30px;

    .feature-list {
      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }

      .feature-group-title {
        display: block;
        border: 1px solid #000000;
        padding: 10px;
      }

      .feature-group-list {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
      }
    }
  }

  input[type="checkbox"] {
    appearance: checkbox;
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 1;

    &:checked {
      background: #43a047;
      opacity: 0.3;
      color: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .actions {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .feature-permission {
    width: 1000px;
  }
}

@media screen and (max-width: 1024px) {
  .feature-permission {
    width: 800px;
  }
}
