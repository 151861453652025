@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .user-group-list {
    .DATASET__wrapper {
      margin: 20px 0 0 0 !important;

      .DATASET__form-custom {
        display: grid;
        grid-template-columns: 10% auto 15% 15% 20% 10%;
      }
    }
  }

  .add-new-user-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    width: fit-content;

    p {
      margin: 0;
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .user-group-list {
    .DATASET__wrapper {
      margin: 20px 0 0 0 !important;

      .DATASET__form-custom {
        grid-template-columns: 10% auto 15% 15% 20% 10%;
        overflow-x: scroll;
      }
    }
  }

  .add-new-user-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    width: fit-content;

    p {
      margin: 0;
    }
  }
}

@include media-breakpoint-down(lg) {
  .user-group-list {
    .DATASET__wrapper {
      margin: 20px 0 0 0 !important;

      .DATASET__form-custom {
        grid-template-columns: 10% auto 15% 15% 20% 10%;
        overflow-x: scroll;
      }
    }
  }

  .add-new-user-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    width: fit-content;

    p {
      margin: 0;
    }
  }
}
