.disposal_expand_container {
  cursor: pointer;
}
.expandBtn_container {
  width: 1.2rem;
}
.expandBtn_container + span:hover {
  text-decoration: underline;
}
.disposal_expand_container span {
  padding: 0.5rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.expandBtn_container.rotate {
  transform: rotateZ(180deg);
  transition: all 200ms ease;
}
.cancellationPopUpButton_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin-top: auto;
}
.cancellationPopUpButton_wrapper button:first-child {
  margin-right: 2rem;
}

.cancellationPopUpButton_wrapper button {
  width: 10rem;
}
.cancellation_popUp {
  height: 360px;
  max-height: 100%;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
}
.cancellation_popUp textarea {
  min-height: 7rem;
  margin-top: 1rem;
}

.disposalBtn_wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.disposalBtn_wrapper > button {
  flex: 1;
  width: 100%;
}

.disposalJournalTable {
  margin-bottom: 3rem;
}

.disposalJournalTable .fgHead {
  font-size: 1rem;
}
