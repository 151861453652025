@import 'bootstrap/scss/_functions.scss';
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .user-list {
    .DATASET__wrapper {
      .DATASET__form-custom {
        display: grid;
        grid-template-columns: 10% 15% 10% 10% 10% 300px auto;
      }
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .user-list {
    .DATASET__wrapper {
      .DATASET__form-custom {
        grid-template-columns: 10% 12% 10% 10% 10% 280px auto;
        overflow-x: scroll;
      }
    }
  }  
}

@include media-breakpoint-down(lg) {
  .user-list {
    .DATASET__wrapper {
      .DATASET__form-custom {
        grid-template-columns: 16% 18% 16% 16% 16% 180px auto;
        overflow-x: scroll;
      }
    }
  }  
}
